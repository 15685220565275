
.cursor{
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--seco-color);
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1000;
    transition: all 1ms ease;
    -webkit-backdrop-filter: invert(100%);
    backdrop-filter: invert(100%);
    pointer-events: none;
    transition-property:none;
}

.expand{
    animation: 75ms ease 0s 1 scaleUpDown backwards;
}

.mouseOver{
    animation: 100ms ease 0s 1 ScaleUp forwards;
}

.crosshair{
    overflow: hidden;
    max-width: 100%;
    max-height: 90%;
    z-index: -1000;
}

.blured-follower{
    position: fixed;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--text-color);
    pointer-events: none;
    z-index: -1000;
    transition: all 1ms ease;
    filter: blur(25px);

    animation: 7000ms ease-in-out 1s ScaleUp infinite alternate;
    transition-property:none;
}

@keyframes scaleUpDown {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform:translate(-50%, -50%) scale(0.5);
    }

    100% {
        transform:translate(-50%, -50%) scale(1);
    }
    
}

@keyframes ScaleUp {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    100% {
        transform:translate(-50%, -50%) scale(1.5);
    }
}

@keyframes breathe {
    0% {
        transform: translate(-50%, -50%) scale(.5);
    }

    50% {
        transform:translate(-50%, -50%) scale(2);
    }

    100% {
        transform:translate(-50%, -50%) scale(.5);
    }
    
}

@media (max-width: 1025px){
    .cursor{
        display: none !important;
    }

    .crosshair{
        display: none !important;
    }

    .blured-follower{
        display: none !important;
    }
}