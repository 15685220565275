.background{
    position: fixed;
    display: grid;
    grid-template-rows: auto auto auto; /* Three rows of equal height */
    row-gap: 0%;
    overflow: hidden;
    max-width: 100%;
    min-height: 100%;
    background-color: var(--dark-color);
}
.bg-img{
    position: absolute;
    background-repeat: no-repeat;
    background-size:cover;
    min-width: 100%;
    min-height: 100%;
}

.row{
    display: flex;
    flex-direction: row;
    padding: 20px;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: left;
    text-align: left;


}

.scrollright{
    animation: move 85s infinite linear;
}

.scrollright2{
    animation: move 75s infinite linear;
}

.reversed{
    animation: move 55s infinite linear reverse;
}



.Skill {
    font-size: 1100%;
    font-weight: bold;
    color: rgba(var(--off-white), .1); /* Set text color to transparent */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
    line-height: 105%;
    transition: color 2s; /* Add transitions for color and text stroke */
    padding: 10px;
    margin-top: 0%;
    margin-bottom: 0%;
    margin-right: 5vh;
    margin-left: 5vh;
    max-height: 30vh;
    white-space: nowrap;
    animation: fadeIn 4s linear forwards;
    overflow: hidden;
}

.Skill:hover {
    color: rgba(247, 133, 54, 0.6); /* Change color on hover */
    
}

.line{
    max-width: 1px;
    max-height: 80%;
    min-height: 80%;
    background-color: #000000;
    padding: 1px;
    margin: 1px;
}

@keyframes move {
    to {
        transform: translateX(-72%);
    }
}

@keyframes fadeIn {
    0% {
        -webkit-text-stroke-color: #00000000;
    }

    
    70% {
        -webkit-text-stroke-color: #00000000;
    }

    100% {
        -webkit-text-stroke-color: rgba(66, 66, 66, 0.1);
    }
    
}




