.HiddenBody{
    position: absolute;
    z-index: 5;
    top: 100%;
    min-height: 100vh;
    max-width: 100%;
    /* background-color: var(--main-color);
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    background: linear-gradient(80deg, var(--main-color) 0%, var(--mid-color) 100%); */

    background: rgba(255, 255, 255, 0.39);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.6px);
    -webkit-backdrop-filter: blur(11.6px);
    border: 1px solid rgba(255, 255, 255, 0.24);

    animation: 4s ease-in-out 0s 1 coomIn forwards ;

    border-radius: 5vh 5vh 0 0;

}

.linked{
    text-decoration: none;
    white-space: nowrap;
    color: var(--text-color);
    cursor: none;
}

.expereince{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.top-header{
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    align-items: center;
    align-self: center;

    p{
        max-width: 80%;
    }

}

.line-long{
    max-width: 1px;
    max-height: 90%;
    min-height: 90%;
    background-color: #000000;
    padding: 1px;
    margin: 1px;
}

.bait{
    position: sticky;
    font-size: larger;
    margin-top: 1.5vh;
}

.bounce{
    animation: 2s ease-in-out 0s scale-bounce forwards infinite ;
}

.container{

        border-bottom: 10vh;
        background: var(--main-color);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(11.6px);
        -webkit-backdrop-filter: blur(11.6px);
        border: 1px solid rgba(255, 255, 255, 0.24);
        min-width: 100%;
        margin-top: 5vh;
        margin-bottom: 5vh;
        align-items: center;

}

.projects{
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.card{
    min-width: 20vw;
    max-width: 80%;
    min-height: 40vh;
    background-color: var(--main-color);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 250ms ease;
    display: flex;
    justify-content:start;
    align-items: center;
    flex-direction: column;
    margin: 2vw;
    overflow: hidden;
    
}

.card:hover{
    scale: 103%;

}

.card-icon{
    background-color: var(--seco-color);
    width: 100%;
    height: max-content;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px inset;
}

.header-title{
    margin: 1vh;
    align-self: start;
    padding: 5px;
    justify-self: center;
    text-align: left;
    overflow: hidden;
    font-size: larger;
    font-weight: bold;
    border-left: 2px solid lightgrey;
    border-top: 2px solid lightgrey;
    border-radius: 5px;
    color: var(--texthl-color);
}

.header-title:hover{
    margin: 1vh;
}

.desc{
    color: #000000;
    max-width: 80%;
}

.tech{
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 5px;
        
}

.pill{
    min-width:fit-content;
    min-height: fit-content;
    color: var(--textpl-color);
    background-color: var(--pill-color);
    margin: 5px;
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 1px 5px inset;
    justify-content: center;
    align-items: center;
    font-size: medium   ;
}


.Contacts{
    display: grid;
    grid-template-columns: auto auto auto;
    align-self: center;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.06);
    max-width: 100%;
    overflow: hidden;
    white-space: wrap;
}

.col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding: 5vh;
    max-height: min-content;

    p{
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

}

.job{
    display: flex;
    flex-direction: row;
    padding: 2vw;
}

.job-title{
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: end;
    padding-right: 2vw;
}

.job-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2vw;
}

.expereince{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    flex-direction: column;
}

@media (max-width: 1025px) {
    .projects{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card{
        min-width: 80%;
        max-width: 80%;
        min-height: 50vw
    }

    .job{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2vw;
    }
    .job-title{
        justify-content: center;

        align-items: center;
    }
}

@keyframes coomIn {
    0% {
        top: 100%;
    }

    
    90% {
        top: 100%;
    }

    100% {
        top: 95%;
    }
    
}

@keyframes scale-bounce {
    0% {
        scale: 100%;
    }

    50% {
        scale: 110%;
    }

    100% {
        scale: 100%;
    }
}