

.info{
    position: relative;
    color: rgb(247, 133, 54);
    line-height: 2rem;
    z-index: 1;
    font-size: larger;
    font-weight: bold;
    text-shadow:  black 1px 0 10px;

    animation: fadeIn 4s linear forwards;
}

@keyframes fadeIn {
    0% {
        color: #00000000;
    }

    
    90% {
        color: #00000000;
    }

    100% {
        color: rgb(247, 133, 54);
    }
    
}