
.titleTile{
    flex-direction: column;
    margin-left: 10%;
    margin-top: 5%;
    width: 80%;
    height: 50vh;
    padding: 2rem 0;
    overflow: hidden;
    background: none;
    background-color: #00000000;
    text-align: left;
}

.character{
  font-size: 5rem;
  color: var(--text-color);
  letter-spacing: 1rem;

  &:hover{
    animation: 0.1s ease-in-out 0s 1 hoverChange forwards;
  }

}

.nameTileText{
  position: relative;
  color: black;
  line-height: 2rem;
  z-index: 1;
  text-align: center;
}

.fade {
  animation: 1s ease-in-out 0s 1 slideInLeft forwards;
}

@keyframes slideInLeft {
  0% {
    left: -100%;
  }
  100% {
    left: translateX(0);
  }
}

@keyframes hoverChange {
  0% {
    color: var(--text-color);
  }
  100% {
    color: var(--mid-color);
  }
  
}

.red{
  color: #dfebed;
}

.green{
  color: var(--text-color);
}