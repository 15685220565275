@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;600;700;800;900&display=swap");

:root{
  --main-color:#E2F0F9;
  --seco-color:#497285;
  --mid-color:#85a6b1;
  --pill-color:#2b4450;
  --textpl-color: white;
  --back-color:#f78536;
  --dark-color: #2b4450;
  --text-color:#000000;
  --textdk-color: grey;
  --texthl-color: #2d2b31;
  --btn-color:var(--main-color);
  --off-white: 223, 235, 237;
  cursor:none;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;

}

.App {
  text-align: left;
}

body{
  background-color:var(--main-color);
  color:var(--text-color);
  font-family: "League Spartan", system-ui, sans-serif;
  font-size: 1.2rem;
  margin:0;
  padding:0;
  line-height: calc(1.3 * 1.2rem);
  
}

.title-container{
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 3%;
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
  }
}  

.circle:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 32% 58% 69% 43% / 48% 32% 59% 55%;
  transition: 0.5s;
  animation: animate 6s linear infinite;
  background: rgb(var(--off-white), 0.2);
}

.circle:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  transition: 0.5s;
  animation: animate 4s linear infinite;
  background: rgb(var(--off-white), 0.1);
}

.circle:nth-child(3) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 31% 45% 74% 35% / 38% 56% 51% 87%;
  transition: 0.5s;
  animation: animate2 10s linear infinite;
  background: rgb(var(--off-white), 0.3);
}

@keyframes animate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes animate2 {
  0% {
      transform: rotate(360deg);
  }
  100% {
      transform: rotate(0deg);
  }
}